.list{
  height: 100%;
  .menu{
    padding-top: 40px;
    li{
      padding-left: 40px;
      position: relative;
      height: 50px;
      line-height: 40px;
      display: flex;
      font-size: 14px;
      align-items: center;
      cursor: pointer;
      color:#fff;
      &:hover{
        color: #3365b9;
      }
      i{
        margin-right: 10px;
      }
      &.is-active{
        background-image: url(#{$app-path}img/nav-menu-img.png);
        background-repeat: round;
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .el-menu{
    border-right: none;
  }
  .el-menu.el-menu--horizontal {
    border-color: rgb(33, 37, 40);
    border-width: 2px;
  }
  .el-menu i {
    margin-right: 5px;
  }
  .content{
    display: flex;
    flex-wrap: wrap;
    &__header{
      padding-left: 40px;
      width: 100%;
      height: 150px !important;
    }
    &__box{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &__nav{
      justify-content: space-between;
    }
    &__add {
      margin: 20px 0 10px 0;
      width: 258px;
      height: 78px;
      padding:0 20px;
      box-sizing: border-box;
      vertical-align: middle;
      border: 1px solid #00baff;
      color: #8eeeff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      cursor: pointer;
      background-image: linear-gradient(-90deg, rgba(0, 222, 255, .39) 0, rgba(0, 174, 255, .19) 100%);
      box-shadow: 0 0 10px 0 rgba(55, 224, 255, .3);
      font-size: 14px;
      cursor: pointer;
      img{
        margin-right: 30px;
      }
      p {
        letter-spacing: 2px;
      }

    }
    &__page{
      padding-right: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__item{
      position: relative;
      margin: 16px;
      display: flex;
      flex-direction: column;
      width: 290px;
      height: 184px;
      border: 1px solid #3a4659;
      box-sizing: border-box;
      overflow: hidden;
      &:hover {
        box-shadow: 0 0 20px 0 #000;
        border: 1px solid #00baff;
      }
    }
    &__main {
      font-size: 12px;
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      justify-content: space-between;
      background: #1d262e;
      box-sizing: border-box;
      padding: 0 10px;
      color: #bcc9d4;
      a{
        color:#bcc9d4;
      }
      i{
        margin-right: 1px;
      }
    }
    &__view{
      margin-right: 2px;
    }
    &__menulist{
      display: flex;
      i{
        margin-right: 10px;
      }
    }
    &__status--active {
      color: #fff
    }
    &__name {
      width: 100px;
      padding: 0 5px;
      line-height: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border: 1px solid transparent;
    }
    &__info {
      position: relative;
      height: calc(100% - 36px);
    }
    &__menu {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(29,38,46,0.8);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__btn {
      margin: 5px 0;
      display: inline-block;
      vertical-align: middle;
      height: 34px;
      line-height: 34px;
      padding: 0 40px;
      box-sizing: border-box;
      outline: 0;
      text-align: center;
      font-size: 14px;
      background-image: linear-gradient(-225deg, #00d3f1 0, #12b3ff 100%);
      background-color: #2681ff;
      border-color: #2681ff;
      color:#fff;
      border: none;
      transition: .3s ease;
      cursor: pointer
    }
    &__info{
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--active{
      box-shadow: 0 0 20px 0 #000;
      border:1px solid #00baff;
    }
  }
}

.icon-ul li{
  width:32%;
  margin-right: 1%;
  display: inline-block;
  line-height:30px;
  cursor: pointer;
  padding-left:20px;
  box-sizing: border-box;
  border-radius: 5px;
}
.icon-ul li i,.icon-ul li div{
  display: inline-block;
}
.icon-ul li div{
  padding-left:10px;
}
.icon-ul li:hover{
  background-color: #12b3ff;
  color:#fff;
}
.el-select-dropdown__item .el-tree{
  background:#27343e;
}
.el-select-dropdown__item .el-tree-node__content:hover,.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  background-color: rgba(0, 192, 222, 0.1);
  color:#fff;
}