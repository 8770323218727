@import "@/styles/echart.scss";
$app-path: "/";

























































.nav-main {
  z-index: 1024;
  position: absolute;
  bottom: 8px;
  display: flex;
  width: 100%;
  user-select: none;
  height: 50px;
  .nav-span {
    .nav-link {
      transition: color 0.2s;
      text-decoration: none !important;
      display: block;
      color: #b9c2cc;
      width: auto;
      min-width: 140px;
      line-height: 40px;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      padding: 0 40px;

      &.nav-active,
      &:hover {
        color: #fff !important;
      }
    }
  }
}
