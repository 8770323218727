
@keyframes rotating{
  from{transform:rotate(0)}
  to{transform:rotate(360deg)}
}
.avue-echart{
&-tabs{
  .el-select{
    width: 100%;
  }
  .el-select,.el-input,input{
    height: 100%;
  }
  .el-input{
    font-size:inherit;
  }
  &__list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
  &__icon{
    margin-right: 10px;
  }
  &__item{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    box-sizing: border-box;
  }
}
&-iframe{
  iframe{
    border:none;
    width:100%;
    height: 100%; 
  }
}
&-text{
  overflow: hidden;
  &__box{
    width: 100%;
    height: 100%;
  }
  &__text{
    display: block;
    text-decoration: none;
  }
}
&-swiper{
  .el-carousel,.el-carousel__container{
    height: 100%;
  }
  img{
    width: 100%;
    height: 100%;
    user-select: none;
  }
}
&-pie{
  &__title{
    position: absolute;
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
&-table{
 height: 100%;
 .el-table{
   background-color: transparent;
 }
 .el-table::before{
   display: none;
 }
 .el-table colgroup.gutter, .el-table th.gutter{
   display: none !important;
 }
 .el-table td, .el-table th.is-leaf{
   border-bottom:none;
 }
 .el-table tbody tr:hover>td { 
   background-color: transparent 
  }
 .el-table__body-wrapper::-webkit-scrollbar {
  width: 0px;
}
}
&-header{
  padding: 10px;
  &--center{
    text-align: center;
  }
  &--right{
    text-align: right;
  }
  &__title{
    margin-right: 5px;
    font-size: 18px;
    font-weight: 400;
    color:#333;
    text-decoration: none;
  }
  &__info{
    display: inline-block;
  }
}
&-progress{
  position: relative;
  &__text{
    &--circle{
      position: absolute;
      width:100%;
      top:50%;
      transform:translate(0,-50%);
      text-align: center;
    }
    &--line{
      margin-bottom: 20px;
      position: relative;
      display: flex;
      align-items: center;
      p{
        margin-right: 5px;
      }
    }
  }
}
&-img{
  &--rotate{
    animation:rotating 0s linear infinite;
  }
}
&-flop{
  .el-tooltip{
    width: 100%;
  }
  &__count{
    flex:1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &-right{
      justify-content:flex-end;
    }
    &-left{
      justify-content:flex-start;
    }
  }
  &__item{
    font-family: "Microsoft Yahei";
    font-size: 28px;
    font-weight: normal;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    display: inline-block;
    &--img{
      padding: 0 5px;
      border-image-slice: 10 16 15 10 fill;
      border-width: 10px 16px 15px 10px;
      border-style: solid;
    }
    &--none{
      padding: 0 !important;
      border-width:0 !important;
      background-color: transparent !important;
    }
  }
}
&-map{
  position: relative;
  &__bg{
    position: absolute;
    top:0;
    left:0;
  }
  &__box{
    position: relative;
  }
  &__location{
    position: absolute;
    background-color: red;
    width: 10px;
    height: 10px;;
    &:hover{
      background-color: green;
    }
  }
}
&-time{
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    font-size: 48px;
    color:#fff;
  }
}
}