@import "@/styles/echart.scss";
$app-path: "/";














































































.imgTabs {
  &__list {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__carousel {
    margin: 0 auto;
    background-color: rgba(180, 181, 198, 0.1);
    background-clip: padding-box;
    opacity: 1;
    filter: blur(0px);
    border-image-source: url(/img/border/border1.png);
    border-image-slice: 10 16 15 10 fill;
    border-width: 10px 16px 15px 10px;
    border-style: solid;
    box-sizing: border-box;
  }
  &__item {
    width: 100%;
    height: 40px;
    background-image: url(/img/banner/banner4.png);
    background-size: 100% 100%;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 0px;
    margin: 0px 8px;
    font-size: 20px;
    color: rgb(77, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  &--active {
    background-image: url(/img/banner/banner5.png);
    color: rgb(77, 255, 255);
  }
}
