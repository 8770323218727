@import "@/styles/echart.scss";
$app-path: "/";





















































































































































































































































.content {
  display: flex;
  .monaco_editor_container {
    flex: 1;
    &:first-child {
      flex: 2;
    }
  }
}
