@import "@/styles/echart.scss";
$app-path: "/";











































































































































































































































































































































































































































































































































































































































































































































































































































































































@import "../styles/style.scss";
.refer-line-img {
  position: absolute;
  left: 0;
  z-index: 5;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
#screens {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.screen-container {
  position: relative;
  width: 5000px;
  height: 3000px;
  background: url(https://img.alicdn.com/tfs/TB184VLcPfguuRjSspkXXXchpXa-14-14.png)
    repeat;
}

.dragghanle {
  cursor: pointer;
}
.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
}
.section {
  flex: 1;
  overflow: hidden;
  position: relative;
}
