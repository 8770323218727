@import "@/styles/echart.scss";
$app-path: "/";











































































.imgItem {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &_box {
    box-sizing: border-box;
    padding: 20px 30px 5px 30px;
  }
  &_item {
    list-style: none;
    padding: 15px 25px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #fff;
    border-radius: 3px;
    background-color: rgba(180, 181, 198, 0.1);
    background-clip: padding-box;
    opacity: 1;
    filter: blur(0px);
    border-image-source: url(/img/border/border1.png);
    border-image-slice: 10 16 15 10 fill;
    border-width: 10px 16px 15px 10px;
    border-style: solid;
    box-sizing: border-box;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_img {
    width: 100px;
    height: 110px;
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }
  &_right {
    padding-left: 20px;
  }
  &_top {
    margin-bottom: 10px;
  }
  &_tag {
    float: right;
    span {
      position: relative;
      padding: 2px 10px 2px 10px;
      font-size: 10px;
      margin-left: 10px;
    }
  }
  &_content {
    line-height: 25px;
    overflow: hidden;
    height: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-indent: 2em;
  }
  &_name {
    display: inline-block;
    width: 80px;
    color: #73fbf9;
    font-weight: bold;
  }
}
